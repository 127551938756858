import styles from "./App.module.scss";
import Card from "./components/card/Card";
import freeJSON from "./store/free.json"

function App() {
    const items = freeJSON.items

  return (
    <div className={styles.app}>
        <div className={styles.bg}>
            <img className={styles['bg__img']} src="/images/bg.jpg" alt="background"/>
        </div>
      <header className={styles.header}>
        <div className="container">
            <div className={styles['header__inner']}>
                <img src="/logo.png" height="60" alt="logo"/>
                <span>CS 2 Free</span>
            </div>
        </div>
      </header>
        <main className={styles.main}>
            <div className="container">
            <h1 className={styles.title}>Бесплатные скины для CS 2, CS:GO</h1>
            <p className={styles.text}>Добро пожаловать на страницу, где вы найдете подборку лучших сайтов, предлагающих бесплатные бонусы для азартных игр по тематике Counter Strike. Если вы хотите испытать удачу и получить шанс выиграть ценные призы без необходимости вкладывать собственные средства, то вы попали по адресу! Мы собрали для вас проверенные ресурсы, на которых можно получить бесплатный баланс для ставок, кейсов и рулеток.</p>
            <div className={styles.cards}>
                {items.map((item, index) => <Card item={item} key={index} />)}
            </div>
        </div>
      </main>
    </div>
  );
}

export default App;
