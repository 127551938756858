import Button from "../button/Button";
import styles from "./Card.module.scss";

export default function Card({ item }) {
    const { name, bonus, image, link, services, code } = item

    function goToSite() {
        if(code && navigator?.clipboard) {
            navigator.clipboard.writeText(code)
        }
        window.open(link, '_blank')
    }

  return (
    <div className={styles.card}>
      <div className={styles["card__top"]}>
        <div className={styles["card__name"]}>{ name }</div>
        <div className={styles["card__profit"]}>Бонус - <span>{ bonus }$</span></div>
      </div>
      <img
        className={styles["card__image"]}
        src={`/images/${image}`}
        height={250}
        alt="Site"
      />
        { services && services.length ? services.map((service, index) => Service(service, index)) : '' }
      <div className={styles["card__bottom"]}>
          <div className={styles["card__button-wrapper"]}>
              <Button className={styles["card__button"]} onclick={goToSite}>{code ? 'Скопировать код и перейти' : 'Перейти'}</Button>
              {code ? <div className={styles["card__button-hint"]}>Нужно ввести скопированный код на странице бонуса.</div> : ''}
          </div>
      </div>
    </div>
  );
}

function Service(service, index) {
    return (
        <div className={styles["card__service"]} key={index}>
            <img className={styles["card__service-img"]} src="/icons/checkmark.png" alt=""/>
            <span className={styles["card__service-text"]}>{ service }</span>
        </div>
    )
}